.request-creation {
    width: 100%;
    height: 100%;
}
.create-order-header {
    width: 100%;
    height: 15%;
}
.create-request {
    width: 100%;
    height: 70%;
}
.create-order-footer {
    width: 100%;
    height: 15%;
}
.request-creation__file-upload {
    height: 60%;
    width: 30%;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 10px 1px gray;
    border: 1px dashed lightgray;
    padding: 2%;
}
.file-upload-err-msg {
    color: red;
    padding-left: 5px;
    font-size: 10px;
}
.request-creation__info {
    height: 80%;
    width: 50%;
    text-align: center;
}
.request-creation__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.5;
    color: black;
}
.request-file-info {
    height: 33%;
    width: 80%;
    align-self: flex-start;
    align-content:stretch;
}
.create-order-footer button:first-child:disabled {
    background-color: #2856AF;
    opacity: 0.5;
}