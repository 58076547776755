.panel-header {
  display: flex;
  justify-content: space-between;
  background-color: #2856AF;
  width: 100%;
  height: fit-content;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px 0px 15px;
  vertical-align: middle;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.template-details {
  color: #FFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
#progressbar {
  background-color: #FFFF;
  border-radius: 15px;
  /* (height of inner div) / 2 + padding */
  padding: 3px;
  width: 200px
}
#progressbar>div {
  background-color: #16C612;
  width: 53%;
  /* Adjust with JavaScript */
  height: 10px;
  border-radius: 15px;
}
.progress-icon {
  margin-right: 10px;
}