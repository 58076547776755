.click{
    padding: 10px;  
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.2); 
    transition: transform .2s; /* Animation */
}
.click:hover{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transform: scale(1.1)
}
.btn-more{
    border: none;
    width: 50%;
    font-weight: 600;
    color: hsl(0, 0%, 33%);
}
.prog_text{
    font-weight: 600;
    font-size:17px;
    color: #2856AF;
}
.markt_head{
    font-weight: 500;
    font-size:16px;
    color: #2856AF;
}
.markt_bottom{
    font-size:15px;
    color: #797979;

}
.start_process{
    width: 300px;
    height: 150px;
}
.upload{
    color: rgb(240, 99, 44);
}
.qc{
    color: #306fec;
}
.dwnload{
    color: rgb(108, 216, 108);
}