
 .title {
    font-size: 55px;
    font-weight: bold;
    font-family: 'Roboto';
    color: rgb(38, 189, 118);
    letter-spacing: 2px;
    padding-top: 50px;
}

.loginBtn{
    background-color: #2856AF;
    color: white;  
    transition: transform .2s; /* Animation */
    
}
.loginBtn:hover{
    transform: scale(1.2);
    background-image: linear-gradient(to right, #3CA55C 0%, #B5AC49  51%, #3CA55C  100%);
    color: white; 
    font-weight: bold;
}

.desc{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(49, 49, 49);
    font-weight: bolder;
    font-size:40px;
}
.logout-btn:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.logout-btn{
    transition: transform .2s; /* Animation */
}
